import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  readonly translationKeyMap = new Map([
    ['members.errors.invalidEmail',
      'Invalid email address'],
    ['members.errors.onlyBSIEmailForSuperadmin',
      'Only a member with a BSI email address can have a superadmin policy'],
    ['members.errors.onlyBSIEmailForAdmin',
      'Only a member with a BSI email address can have the platform admin policy'],
    ['members.errors.workspaceAdminLimitExceeded',
      'You do not have enough Admin policies available.'],
    ['members.errors.workspaceStandardLimitExceeded',
      'You do not have enough Standard policies available.']
  ]);

  translate(key: unknown): string {

    if (key instanceof Error) {
      key = key.message;
    } else if (key instanceof HttpErrorResponse) {
      key = key.error.detail;
    }

    return key
      ? this.translationKeyMap.get(key as string) || key as string|| 'General error'
      : key as string || 'General error';
  }
}
