import { Injectable } from '@angular/core';
import { DocumentApiService, Library, LibraryService, TranslationService } from '@core/services';
import { DefaultDocuments, DocumentTypes } from '@shared/reference';
import { Observable, catchError, firstValueFrom, forkJoin, from, map, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '@env';
import { Document } from '@core/models';

export interface Template {
  id: string;
  name: string;
  workspaceAdminLimit: number;
  workspaceStandardLimit: number;
}

export interface DocumentItem {
  id: string;
  templateIds: string[];
  document: Document;
  isCommon: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PopulateService {
  private isInitialised = false;
  private documentCache: DocumentItem[] = [];

  constructor(
    private readonly documentApiService: DocumentApiService,
    private readonly library: LibraryService,
    private readonly translationService: TranslationService,
  ) { }

  async init(): Promise<boolean> {
    if (!this.isInitialised) {
      try {
        const isLoaded = await firstValueFrom(this.loadFromLibrary());
        this.isInitialised = isLoaded;
        return isLoaded;
      } catch (e) {
        console.error(e);
      }
    }
    return this.isInitialised;
  }

  getDefaultTemplateId(): string {
    return 'smartAuditV2';
  }

  getTemplates(): Template[] {
    const templates = [
      {
        id: 'smartAuditV2',
        name: 'Plus Training',
        workspaceAdminLimit: 1,
        workspaceStandardLimit: 30
      },
      {
        id: 'starter',
        name: 'Starter',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'takeaway',
        name: 'Plus Basic',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'plusPremium',
        name: 'Plus Premium',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'base',
        name: 'Base',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 10
      },
      {
        id: 'blank',
        name: 'Blank',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 10
      }
    ] as Template[];

    if (!environment.production) {
      templates.push({
        id: 'automation',
        name: 'Automation',
        workspaceAdminLimit: 1,
        workspaceStandardLimit: 30
      });
    }

    templates.sort((a, b) => a.name.localeCompare(b.name));
    return templates;
  }

  loadFromLibrary(): Observable<boolean> {
    return forkJoin([
      this.library.getItem$(Library.DefaultRecordType), // 0
      this.library.getItem$(Library.DefaultRecordTypeTranslation), // 1
      this.library.getItem$(Library.GlobalTranslation), // 2
      this.library.getItem$(Library.LocationRecordType), // 3
      this.library.getItem$(Library.LocationTranslation), // 4
      this.library.getItem$(Library.PersonRecordType), // 5
      this.library.getItem$(Library.PersonTranslation), // 6
      this.library.getItem$(Library.RecordTypeTranslation), // 7
      this.library.getItem$(Library.StarterRecordRecordType), // 8
      this.library.getItem$(Library.StarterRecordTranslation), // 9
      this.library.getItem$(Library.StarterReferenceData), // 10
      this.library.getItem$(Library.StarterReferenceDataTranslation), // 11
      this.library.getItem$(Library.StarterHomePageDocument), // 12
      this.library.getItem$(Library.StarterHomePageTranslation), // 13
      this.library.getItem$(Library.StarterWorkspaceDataDocument), // 14
      this.library.getItem$(Library.UserDataDocument), // 15
      this.library.getItem$(Library.DefaultTermsOfUse), // 16
      this.library.getItem$(Library.DefaultEmailTemplate), // 17

      this.library.getItem$(Library.SmartAuditV2GlobalTranslation), // 18
      this.library.getItem$(Library.SmartAuditV2LocationRecordType), // 19
      this.library.getItem$(Library.SmartAuditV2LocationTranslation), // 20
      this.library.getItem$(Library.SmartAuditV2PersonRecordType), // 21
      this.library.getItem$(Library.SmartAuditV2PersonTranslation), // 22
      this.library.getItem$(Library.SmartAuditV2RecordTypeTranslation), // 23
      this.library.getItem$(Library.SmartAuditV2AuditRecordType), // 24
      this.library.getItem$(Library.SmartAuditV2AuditTranslation), // 25
      this.library.getItem$(Library.SmartAuditV2ChecklistRecordType), // 26
      this.library.getItem$(Library.SmartAuditV2ChecklistTranslation), // 27
      this.library.getItem$(Library.SmartAuditV2FindingRecordType), // 28
      this.library.getItem$(Library.SmartAuditV2FindingTranslation), // 29
      this.library.getItem$(Library.SmartAuditV2ReferenceData), // 30
      this.library.getItem$(Library.SmartAuditV2ReferenceDataTranslation), // 31
      this.library.getItem$(Library.SmartAuditV2HomePageDocument), // 32
      this.library.getItem$(Library.SmartAuditV2HomePageTranslation), // 33
      this.library.getItem$(Library.SmartAuditV2WorkspaceDataDocument), // 34

      this.library.getItem$(Library.AutomationGlobalTranslation), // 35
      this.library.getItem$(Library.AutomationLocationRecordType), // 36
      this.library.getItem$(Library.AutomationLocationTranslation), // 37
      this.library.getItem$(Library.AutomationPersonRecordType), // 38
      this.library.getItem$(Library.AutomationPersonTranslation), // 39
      this.library.getItem$(Library.AutomationRecordTypeTranslation), // 40
      this.library.getItem$(Library.AutomationActionRecordType), // 41
      this.library.getItem$(Library.AutomationActionTranslation), // 42
      this.library.getItem$(Library.AutomationAuditRecordType), // 43
      this.library.getItem$(Library.AutomationAuditTranslation), // 44
      this.library.getItem$(Library.AutomationChecklistRecordType), // 45
      this.library.getItem$(Library.AutomationChecklistTranslation), // 46
      this.library.getItem$(Library.AutomationFindingRecordType), // 47
      this.library.getItem$(Library.AutomationFindingTranslation), // 48
      this.library.getItem$(Library.AutomationReferenceData), // 49
      this.library.getItem$(Library.AutomationReferenceDataTranslation), // 50
      this.library.getItem$(Library.AutomationHomePageDocument), // 51
      this.library.getItem$(Library.AutomationHomePageTranslation), // 52
      this.library.getItem$(Library.AutomationWorkspaceDataDocument), // 53

      this.library.getItem$(Library.TakeawayGlobalTranslation), // 54
      this.library.getItem$(Library.TakeawayLocationRecordType), // 55
      this.library.getItem$(Library.TakeawayLocationTranslation), // 56
      this.library.getItem$(Library.TakeawayPersonRecordType), // 57
      this.library.getItem$(Library.TakeawayPersonTranslation), // 58
      this.library.getItem$(Library.TakeawayRecordTypeTranslation), // 59
      this.library.getItem$(Library.TakeawayAuditRecordType), // 60
      this.library.getItem$(Library.TakeawayAuditTranslation), // 61
      this.library.getItem$(Library.TakeawayChecklistRecordType), // 62
      this.library.getItem$(Library.TakeawayChecklistTranslation), // 63
      this.library.getItem$(Library.TakeawayFindingRecordType), // 64
      this.library.getItem$(Library.TakeawayFindingTranslation), // 65
      this.library.getItem$(Library.TakeawayReferenceData), // 66
      this.library.getItem$(Library.TakeawayReferenceDataTranslation), // 67
      this.library.getItem$(Library.TakeawayHomePageDocument), // 68
      this.library.getItem$(Library.TakeawayHomePageTranslation), // 69
      this.library.getItem$(Library.TakeawayWorkspaceDataDocument), // 70
      this.library.getItem$(Library.TakeawayTermsOfUseDocument), // 71

      this.library.getItem$(Library.PlusPremiumGlobalTranslation), // 72
      this.library.getItem$(Library.PlusPremiumLocationRecordType), // 73
      this.library.getItem$(Library.PlusPremiumLocationTranslation), // 74
      this.library.getItem$(Library.PlusPremiumPersonRecordType), // 75
      this.library.getItem$(Library.PlusPremiumPersonTranslation), // 76
      this.library.getItem$(Library.PlusPremiumRecordTypeTranslation), // 77
      this.library.getItem$(Library.PlusPremiumActionRecordType), // 78
      this.library.getItem$(Library.PlusPremiumActionTranslation), // 79
      this.library.getItem$(Library.PlusPremiumAuditRecordType), // 80
      this.library.getItem$(Library.PlusPremiumAuditTranslation), // 81
      this.library.getItem$(Library.PlusPremiumChecklistRecordType), // 82
      this.library.getItem$(Library.PlusPremiumChecklistTranslation), // 83
      this.library.getItem$(Library.PlusPremiumFindingRecordType), // 84
      this.library.getItem$(Library.PlusPremiumFindingTranslation), // 85
      this.library.getItem$(Library.PlusPremiumReferenceData), // 86
      this.library.getItem$(Library.PlusPremiumReferenceDataTranslation), // 87
      this.library.getItem$(Library.PlusPremiumHomePageDocument), // 88
      this.library.getItem$(Library.PlusPremiumHomePageTranslation), // 89
      this.library.getItem$(Library.PlusPremiumWorkspaceDataDocument), // 90
      this.library.getItem$(Library.PlusPremiumTermsOfUseDocument) // 91
    ]).pipe(
      map(documents => {
        this.documentCache = [
          {
            id: DefaultDocuments.defaultRecordType,
            templateIds: [],
            isCommon: true,
            document: documents[0]
          },
          {
            id: DefaultDocuments.defaultTranslation,
            templateIds: [],
            isCommon: true,
            document: documents[1]
          },
          {
            id: DefaultDocuments.globalTranslation,
            templateIds: ['starter', 'cmp', 'base'],
            isCommon: true,
            document: documents[2]
          },
          {
            id: DefaultDocuments.locationRecordType,
            templateIds: ['starter', 'cmp', 'base'],
            isCommon: true,
            document: documents[3]
          },
          {
            id: DefaultDocuments.locationTranslation,
            templateIds: ['starter', 'cmp', 'base'],
            isCommon: true,
            document: documents[4]
          },
          {
            id: DefaultDocuments.personRecordType,
            templateIds: ['starter', 'cmp', 'base'],
            isCommon: true,
            document: documents[5]
          },
          {
            id: DefaultDocuments.personTranslation,
            templateIds: ['starter', 'cmp', 'base'],
            isCommon: true,
            document: documents[6]
          },
          {
            id: DefaultDocuments.recordTypeTranslation,
            templateIds: ['starter', 'cmp', 'base'],
            isCommon: true,
            document: documents[7]
          },
          {
            id: DefaultDocuments.starterRecordRecordType,
            templateIds: ['starter'],
            isCommon: false,
            document: documents[8]
          },
          {
            id: DefaultDocuments.starterRecordTranslation,
            templateIds: ['starter'],
            isCommon: false,
            document: documents[9]
          },
          {
            id: DefaultDocuments.starterReferenceData,
            templateIds: ['starter'],
            isCommon: false,
            document: documents[10]
          },
          {
            id: DefaultDocuments.starterReferenceDataTranslation,
            templateIds: ['starter'],
            isCommon: false,
            document: documents[11]
          },
          {
            id: DefaultDocuments.starterHomePageDocument,
            templateIds: ['starter'],
            isCommon: false,
            document: documents[12]
          },
          {
            id: DefaultDocuments.starterHomePageTranslation,
            templateIds: ['starter'],
            isCommon: false,
            document: documents[13]
          },
          {
            id: DefaultDocuments.starterWorkspaceDataDocument,
            templateIds: ['starter', 'base', 'blank'],
            isCommon: false,
            document: documents[14]
          },
          {
            id: DefaultDocuments.userDataDocument,
            templateIds: ['starter', 'cmp', 'smartAuditV2', 'automation', 'takeaway', 'base'],
            isCommon: true,
            document: documents[15]
          },
          {
            id: DefaultDocuments.termsOfUseDocument,
            templateIds: ['starter', 'cmp'],
            isCommon: true,
            document: documents[16]
          },
          {
            id: DefaultDocuments.emailTemplate,
            templateIds: [
              'starter',
              'cmp',
              'smartAuditV2',
              'automation',
              'takeaway',
              'plusPremium',
            ],
            isCommon: true,
            document: documents[17]
          },
          {
            id: DefaultDocuments.smartAuditV2globalTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[18]
          },
          {
            id: DefaultDocuments.smartAuditV2locationRecordType,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[19]
          },
          {
            id: DefaultDocuments.smartAuditV2locationTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[20]
          },
          {
            id: DefaultDocuments.smartAuditV2personRecordType,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[21]
          },
          {
            id: DefaultDocuments.smartAuditV2personTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[22]
          },
          {
            id: DefaultDocuments.smartAuditV2recordTypeTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[23]
          },
          {
            id: DefaultDocuments.smartAuditV2AuditRecordType,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[24]
          },
          {
            id: DefaultDocuments.smartAuditV2AuditTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[25]
          },
          {
            id: DefaultDocuments.smartAuditV2ChecklistRecordType,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[26]
          },
          {
            id: DefaultDocuments.smartAuditV2ChecklistTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[27]
          },
          {
            id: DefaultDocuments.smartAuditV2FindingRecordType,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[28]
          },
          {
            id: DefaultDocuments.smartAuditV2FindingTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[29]
          },
          {
            id: DefaultDocuments.smartAuditV2ReferenceDataDocument,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[30]
          },
          {
            id: DefaultDocuments.smartAuditV2ReferenceDataTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[31]
          },
          {
            id: DefaultDocuments.smartAuditV2HomePageDocument,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[32]
          },
          {
            id: DefaultDocuments.smartAuditV2HomePageTranslation,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[33]
          },
          {
            id: DefaultDocuments.smartAuditV2WorkspaceDataDocument,
            templateIds: ['smartAuditV2'],
            isCommon: false,
            document: documents[34]
          },
          {
            id: DefaultDocuments.automationGlobalTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[35]
          },
          {
            id: DefaultDocuments.automationLocationRecordType,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[36]
          },
          {
            id: DefaultDocuments.automationLocationTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[37]
          },
          {
            id: DefaultDocuments.automationPersonRecordType,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[38]
          },
          {
            id: DefaultDocuments.automationPersonTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[39]
          },
          {
            id: DefaultDocuments.automationRecordTypeTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[40]
          },
          {
            id: DefaultDocuments.automationActionRecordType,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[41]
          },
          {
            id: DefaultDocuments.automationActionTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[42]
          },
          {
            id: DefaultDocuments.automationAuditRecordType,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[43]
          },
          {
            id: DefaultDocuments.automationAuditTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[44]
          },
          {
            id: DefaultDocuments.automationChecklistRecordType,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[45]
          },
          {
            id: DefaultDocuments.automationChecklistTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[46]
          },
          {
            id: DefaultDocuments.automationFindingRecordType,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[47]
          },
          {
            id: DefaultDocuments.automationFindingTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[48]
          },
          {
            id: DefaultDocuments.automationReferenceDataDocument,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[49]
          },
          {
            id: DefaultDocuments.automationReferenceDataTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[50]
          },
          {
            id: DefaultDocuments.automationHomePageDocument,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[51]
          },
          {
            id: DefaultDocuments.automationHomePageTranslation,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[52]
          },
          {
            id: DefaultDocuments.automationWorkspaceDataDocument,
            templateIds: ['automation'],
            isCommon: false,
            document: documents[53]
          },
          {
            id: DefaultDocuments.takeawayGlobalTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[54]
          },
          {
            id: DefaultDocuments.takeawayLocationRecordType,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[55]
          },
          {
            id: DefaultDocuments.takeawayLocationTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[56]
          },
          {
            id: DefaultDocuments.takeawayPersonRecordType,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[57]
          },
          {
            id: DefaultDocuments.takeawayPersonTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[58]
          },
          {
            id: DefaultDocuments.takeawayRecordTypeTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[59]
          },
          {
            id: DefaultDocuments.takeawayAuditRecordType,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[60]
          },
          {
            id: DefaultDocuments.takeawayAuditTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[61]
          },
          {
            id: DefaultDocuments.takeawayChecklistRecordType,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[62]
          },
          {
            id: DefaultDocuments.takeawayChecklistTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[63]
          },
          {
            id: DefaultDocuments.takeawayFindingRecordType,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[64]
          },
          {
            id: DefaultDocuments.takeawayFindingTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[65]
          },
          {
            id: DefaultDocuments.takeawayReferenceDataDocument,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[66]
          },
          {
            id: DefaultDocuments.takeawayReferenceDataTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[67]
          },
          {
            id: DefaultDocuments.takeawayHomePageDocument,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[68]
          },
          {
            id: DefaultDocuments.takeawayHomePageTranslation,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[69]
          },
          {
            id: DefaultDocuments.takeawayWorkspaceDataDocument,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[70]
          },
          {
            id: DefaultDocuments.takeawayTermsOfUseDocument,
            templateIds: ['takeaway'],
            isCommon: false,
            document: documents[71]
          },
          {
            id: DefaultDocuments.plusPremiumGlobalTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[72]
          },
          {
            id: DefaultDocuments.plusPremiumLocationRecordType,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[73]
          },
          {
            id: DefaultDocuments.plusPremiumLocationTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[74]
          },
          {
            id: DefaultDocuments.plusPremiumPersonRecordType,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[75]
          },
          {
            id: DefaultDocuments.plusPremiumPersonTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[76]
          },
          {
            id: DefaultDocuments.plusPremiumRecordTypeTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[77]
          },
          {
            id: DefaultDocuments.plusPremiumActionRecordType,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[78]
          },
          {
            id: DefaultDocuments.plusPremiumActionTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[79]
          },
          {
            id: DefaultDocuments.plusPremiumAuditRecordType,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[80]
          },
          {
            id: DefaultDocuments.plusPremiumAuditTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[81]
          },
          {
            id: DefaultDocuments.plusPremiumChecklistRecordType,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[82]
          },
          {
            id: DefaultDocuments.plusPremiumChecklistTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[83]
          },
          {
            id: DefaultDocuments.plusPremiumFindingRecordType,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[84]
          },
          {
            id: DefaultDocuments.plusPremiumFindingTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[85]
          },
          {
            id: DefaultDocuments.plusPremiumReferenceDataDocument,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[86]
          },
          {
            id: DefaultDocuments.plusPremiumReferenceDataTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[87]
          },
          {
            id: DefaultDocuments.plusPremiumHomePageDocument,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[88]
          },
          {
            id: DefaultDocuments.plusPremiumHomePageTranslation,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[89]
          },
          {
            id: DefaultDocuments.plusPremiumWorkspaceDataDocument,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[90]
          },
          {
            id: DefaultDocuments.plusPremiumTermsOfUseDocument,
            templateIds: ['plusPremium'],
            isCommon: false,
            document: documents[91]
          },
        ] as DocumentItem[];
        return true;
      }),
      catchError(e => {
        console.error(e);
        return of(false);
      })
    );
  }

  getDefaultDocument(id: string): Document | undefined {
    const item = this.documentCache.find(item => item.id === id);
    if (item) {
      return item.document;
    }
    return undefined;
  }

  getDocumentItemsForTemplate(templateId: string): DocumentItem[] {
    return this.documentCache.filter(item => item.templateIds.includes(templateId));
  }

  populate$(workspaceId: string, templateId: string, workspaceDisplayName: string):
    Observable<string[] | null> {
      return from(this.populate(workspaceId, templateId, workspaceDisplayName));
    }

  async populate(workspaceId: string, templateId: string, workspaceDisplayName: string):
    Promise<string[] | null> {

    if (!this.isInitialised) {
      return null;
    }

    const createCalls: Promise<Document>[] = [];
    const recordTypes: string[] = [];

    this.documentCache
      .filter(item => item.templateIds.includes(templateId)
        && item.document.documentType === DocumentTypes.recordType)
      .forEach(item => {
        createCalls.push(this.documentApiService.createRecordType(item.document, workspaceId));
        if (item.document.name) {
          recordTypes.push(item.document.name);
        }
      });

    this.documentCache
      .filter(item => item.templateIds.includes(templateId)
        && item.document.documentType === DocumentTypes.workspaceData)
      .forEach(item => {
        const content = item.document.content;

        if (content.info !== null && content.info !== undefined)
        {
          // Update the workspace info properties
          item.document.content.info.workspaceDisplayName = workspaceDisplayName;
          item.document.content.info.workspaceUrl = environment.workspaceUrl;
          item.document.content.info.applicationLogoDataUrl = '';
          item.document.content.info.reportLogoDataUrl = '';
        }

        createCalls.push(this.documentApiService.createDocument({
          id: workspaceId,
          documentId: workspaceId,
          documentType: item.document.documentType,
          content: item.document.content,
          isPrivate: false
        }, workspaceId));
      });

      this.documentCache
      .filter(item => item.templateIds.includes(templateId)
        && item.document.documentType !== DocumentTypes.recordType
        && item.document.documentType !== DocumentTypes.workspaceData)
      .forEach(item => {
        createCalls.push(this.documentApiService.createDocument(item.document, workspaceId));
      });

    try {
      await Promise.all(createCalls);
      console.log('Populate complete');
      return recordTypes;
    }
    catch (e) {
      console.error(e);
      return null;
    }
  }

  verifyAndRetryPopulate$(workspaceId: string, templateId: string, workspaceDisplayName: string):
    Observable<string[] | null> {
      return from(this.verifyAndRetryPopulate(workspaceId, templateId, workspaceDisplayName));
  }

  async verifyAndRetryPopulate(
    workspaceId: string,
    templateId: string,
    workspaceDisplayName: string): Promise<string[] | null> {

    const documentItemsForTemplate = this.documentCache
      .filter(item => item.templateIds.includes(templateId));

    let recordTypes: Document[];
    let documents: Document[];
    try {
      [recordTypes, documents] = await Promise.all([
        this.documentApiService.getAllRecordTypes(workspaceId),
        this.documentApiService.getAllDocuments(workspaceId)
      ]);
    } catch (e) {
      console.log('Cannot get documents from workspace: '
        + this.translationService.translate(e));
      return null;
    }

    const createCalls: Promise<Document>[] = [];
    const recordTypeNames: string[] = [];

    // Loop through documentItemsForTemplate to verify and create documents
    documentItemsForTemplate.forEach(item => {
      if (item.document.documentType === DocumentTypes.recordType) {
        // Check if all the recordTypes exist
        const recordTypeExists = recordTypes.
          some(d => d.name === item.document.name);

        if (!recordTypeExists) {
          // Create the recordtype if it doesn't exist
          createCalls.push(this.documentApiService.createRecordType(item.document, workspaceId));
          if (item.document.name) {
            recordTypeNames.push(item.document.name);
          }
        }
      }
      else {
        // Check if the document type exists in documents and has content
        const documentExists = documents
          .some(d => d.documentType === item.document.documentType && d.content);

        if (!documentExists) {
          // Create the document if it doesn't exist
          if (item.document.documentType === DocumentTypes.userData) {
            const documentId = uuidv4();
            createCalls.push(this.documentApiService.createDocument({
              id: documentId,
              documentId: documentId,
              documentType: item.document.documentType,
              content: item.document.content,
              isPrivate: false
            }, workspaceId));
          }
          else if (item.document.documentType === DocumentTypes.workspaceData) {
            item.document.content.info.workspaceDisplayName = workspaceDisplayName;
            item.document.content.info.workspaceUrl = environment.workspaceUrl;
            item.document.content.info.applicationLogoDataUrl = '';
            item.document.content.info.reportLogoDataUrl = '';
            const documentId = uuidv4();
            createCalls.push(this.documentApiService.createDocument({
              id: documentId,
              documentId: documentId,
              documentType: item.document.documentType,
              content: item.document.content,
              isPrivate: false
            }, workspaceId));
          }
          else {
            const documentId = uuidv4();
            createCalls.push(this.documentApiService.createDocument({
              id: documentId,
              documentId: documentId,
              documentType: item.document.documentType,
              content: item.document.content,
            }, workspaceId));
          }
        }
      }
    });

    try {
      await Promise.all(createCalls);
      console.log('Populate complete');
      return recordTypeNames;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
