import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';

import { environment } from '@env';

import {
  ServiceVersion
} from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class StorageApiService {

  constructor(private http: HttpClient) { }

  getVersion$(): Observable<ServiceVersion> {
    return this.http.get<ServiceVersion>(environment.storageApiBaseUrl + '/health/version');
  }

  async getVersion(): Promise<ServiceVersion> {
    return await firstValueFrom(
      this.http.get<ServiceVersion>(environment.storageApiBaseUrl + '/health/version'));
  }

  async uploadFile(workspaceId: string, documentId: string, id: string, file: Blob): Promise<object> {
    const formData = new FormData();
    formData.append('file', file);

    return await firstValueFrom(
      this.http.post(
        environment.storageApiBaseUrl + '/' + workspaceId
          + '/storage/' + documentId
          + '/items/?attachmentId=' + id,
        formData,
        { headers: new HttpHeaders({
            'ngsw-bypass': 'true',
          }),
        }
      ));
  }

  async deleteFile(workspaceId: string, documentId: string, id: string): Promise<object> {

    return await firstValueFrom(
      this.http.delete(
        environment.storageApiBaseUrl + '/' + workspaceId
          + '/storage/' + documentId
          + '/items/' + id,
        { headers: new HttpHeaders({
            'ngsw-bypass': 'true',
          }),
        }
      ));
  }

  async downloadFile(workspaceId: string, documentId: string, id: string): Promise<Blob> {
    return await firstValueFrom(
      this.http.get(
        environment.storageApiBaseUrl + '/' + workspaceId
          + '/storage/' + documentId
          + '/items/' + id,
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'ngsw-bypass': 'true',
            'Accept': 'image/*',
          }), }
      ));
  }

  async createImageFromBlob(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

}
