import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import pLimit from 'p-limit';

import { Document, Member, SecurityDocument, Workspace, WorkspaceAccess, WorkspaceContent } from '@core/models';
import { AdminApiService } from './admin-api.service';
import { UserMemberService } from './user-member.service';
import { PopulateService } from './populate.service';
import { DocumentTypes, LevelPolicy, RecordTypes } from '@shared/reference';
import { DocumentApiService } from './document-api.service';
import { MemberService } from './member.service';
import jsonpatch from 'json-patch';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  constructor(
    private readonly adminApiService: AdminApiService,
    private readonly authService: AuthService,
    private readonly documentApiService: DocumentApiService,
    private readonly memberService: MemberService,
    private readonly populateService: PopulateService,
    private readonly userMemberService: UserMemberService,
  ) { }

  convertWorkspaceToWorkspaceDocument(
    workspace: Workspace): SecurityDocument<WorkspaceContent> {
    return {
      id: workspace.id,
      documentType: DocumentTypes.workspace,
      content: {
        displayName: workspace.displayName,
        uriName: workspace.uriName,
        description: workspace.description || '',
        type: workspace.isReference ? 'template' : 'app',
        isEnabled: true,
        tier: 'base',
        templateId: workspace.templateId,
        isReference: workspace.isReference || false,
        workspaceAdminLimit: workspace.workspaceAdminLimit,
        workspaceStandardLimit: workspace.workspaceStandardLimit,
        publicWorkspacePolicy: workspace.publicWorkspacePolicy,
        members: workspace.members.map(m => ({
          id: m.id,
          workspacePolicy: m.workspacePolicy
        })),
        ownerMemberId: workspace.ownerId,
        tags: workspace.tags || [],
      },
    };
  }

  convertWorkspaceDocumentToWorkspace(
    workspaceDocument: SecurityDocument<WorkspaceContent>): Workspace {

    return {
      id: workspaceDocument.id,
      displayName: workspaceDocument.content.displayName,
      uriName: workspaceDocument.content.uriName,
      description: workspaceDocument.content.description,
      templateId: workspaceDocument.content.templateId,
      isReference: workspaceDocument.content.isReference,
      workspaceAdminLimit: workspaceDocument.content.workspaceAdminLimit,
      workspaceStandardLimit: workspaceDocument.content.workspaceStandardLimit,
      publicWorkspacePolicy: workspaceDocument.content.publicWorkspacePolicy,
      members: workspaceDocument.content.members.map(m => ({
        id: m.id,
        name: '',
        email: '',
        workspacePolicy: m.workspacePolicy
      })),
      ownerId: workspaceDocument.content.ownerMemberId,
      tags: workspaceDocument.content.tags,
    };
  }

  async getWorkspaceByOwner(memberId: string): Promise<SecurityDocument<WorkspaceContent> | null> {
    const workspaces = await this.adminApiService.getWorkspaces(`ownerMemberId=${memberId}`);
    return workspaces.length > 0 ? workspaces[0] : null;

  }

  async createWorkspaceWithOwner(
    workspaceToCreate: Workspace,
    members: Member[],
    createDefaultLocations = false): Promise<SecurityDocument<WorkspaceContent>> {

    // Create workspace
    const workspace = await this.adminApiService
      .createWorkspace(this.convertWorkspaceToWorkspaceDocument(workspaceToCreate));

    await this.authService.forceRefreshOfToken();

    const createdRecordTypes = await this.populateService.populate(
      workspace.id,
      workspace.content.templateId || '',
      workspace.content.displayName);

    if (!createdRecordTypes) {
      // Could not populate default content, disable workspace
      const instructions: jsonpatch.OpPatch[] = [
        { op: 'replace', path: '/isEnabled', value: false },
        { op: 'replace', path: '/displayName', value: workspace.content.displayName + '-fail' },
        { op: 'replace', path: '/uriName', value: workspace.content.uriName + '-fail' },
      ];
      await this.adminApiService.patchWorkspace(instructions, workspace.id);
      throw new Error('Populating default content failed after retries');
    }

    await this.memberService.createMissingMembers(members, 'standard');

    // Special logic for persons
    if (createdRecordTypes.includes(RecordTypes.person)) {
      // The workspace has persons, link them to members
      await this.userMemberService.updatePersonsInWorkspace(
        workspace.id,
        members);
    }

    // Special logic for default locations
    if (createdRecordTypes.includes(RecordTypes.location)
      && createDefaultLocations
      && (workspace.content.templateId === 'automation'
        || workspace.content.templateId === 'smartAuditV2')) {
      await this.createDefaultLocations(workspace.id, workspace.content.templateId || '');
    }

    return workspace;
  }

  async updateMemberAccessInWorkspaces(
    memberId: string,
    workspaceAccesses: WorkspaceAccess[],
    workspaceDocuments: SecurityDocument<WorkspaceContent>[] | undefined = undefined,
  ): Promise<void> {

    if (!workspaceDocuments) {
      workspaceDocuments = await this.adminApiService.getWorkspaces();
    }

    if (!workspaceDocuments) {
      throw new Error('Could not get workspaces');
    }

    const workspaceOpLimit = pLimit(10);
    const workspaceOperations: Promise<SecurityDocument<WorkspaceContent>>[] = [];
    for (const workspaceAccess of workspaceAccesses) {
      const workspaceDocument = workspaceDocuments.find(w => w.id === workspaceAccess.workspaceId);

      if (!workspaceDocument) {
        continue;
      }

      const index = workspaceDocument.content.members.findIndex(m => m.id === memberId);
      const currentPolicy = index >= 0
        ? workspaceDocument.content.members[index].workspacePolicy
        : undefined;

      if (currentPolicy !== workspaceAccess.workspacePolicy) {
        if (workspaceAccess.workspacePolicy === LevelPolicy.admin
          && workspaceDocument.content.workspaceAdminLimit
          && workspaceDocument.content.workspaceAdminLimit > 0) {

          const adminMembers = workspaceDocument.content.members
            .filter(m => m.workspacePolicy === LevelPolicy.admin);

          if (adminMembers.length > workspaceDocument.content.workspaceAdminLimit) {
            throw new Error(`The workspace "${workspaceDocument.content.displayName}" has `
              + 'the maximum number of Admin users.');
          }
        }

        if (workspaceAccess.workspacePolicy === LevelPolicy.standard
          && workspaceDocument.content.workspaceStandardLimit
          && workspaceDocument.content.workspaceStandardLimit > 0) {

          const standardMembers = workspaceDocument.content.members
            .filter(m => m.workspacePolicy === LevelPolicy.standard);

          if (standardMembers.length > workspaceDocument.content.workspaceStandardLimit) {
            throw new Error(`The workspace "${workspaceDocument.content.displayName}" has `
              + 'the maximum number of Standard users.');
          }
        }

        if (index < 0) {
          const instructions: jsonpatch.OpPatch[] = [
            {
              op: 'add',
              path: '/members/-',
              value: {
                id: memberId,
                workspacePolicy: workspaceAccess.workspacePolicy,
              }
            },
          ];
          workspaceOperations.push(workspaceOpLimit(() => this.adminApiService.patchWorkspace(
            instructions,
            workspaceAccess.workspaceId)));
        } else {
          const instructions: jsonpatch.OpPatch[] = [
            {
              op: 'replace',
              path: `/members/${index}/workspacePolicy`,
              value: workspaceAccess.workspacePolicy
            },
          ];
          workspaceOperations.push(workspaceOpLimit(() => this.adminApiService.patchWorkspace(
            instructions,
            workspaceAccess.workspaceId)));
        }
      }
    }
    await Promise.all(workspaceOperations);
  }


  // workspaceTemplateId === 'automation' || workspaceTemplateId === 'smartAuditV2'
  async createDefaultLocations(workspaceId: string, _workspaceTemplateId: string): Promise<void> {
    const locations = ['London', 'Mumbai', 'Hong Kong', 'Johor', 'Haarlemweg', 'Singapore'];
    const limit = pLimit(5);
    const createCalls: Promise<Document>[] = [];

    for (const location of locations) {
      const recordId = uuidv4();
      const locationRecord: Document = {
        id: recordId,
        documentId: recordId,
        documentType: 'record',
        content: {
          recordType: RecordTypes.location,
          fields: {
            subType: RecordTypes.location,
            state: 'draft',
            name: location,
            description: '-',
            postalCode: '-'
          },
          links: {}
        }
      };
      createCalls.push(limit(() =>
        this.documentApiService.createRecord(locationRecord, workspaceId)));
    }
    await Promise.all(createCalls);
  }

  validateWorkspacePolicyLimits(workspaceDocument: SecurityDocument<WorkspaceContent>): string | null {
    if (!workspaceDocument.content.workspaceAdminLimit
      || workspaceDocument.content.workspaceAdminLimit === -1) {
      return null;
    }

    const adminCount = workspaceDocument.content.members
      .filter(m => m.workspacePolicy === LevelPolicy.admin).length;

    if (adminCount > workspaceDocument.content.workspaceAdminLimit) {
      return 'The workspace has the maximum number of Admin users.';
    }

    const standardCount = workspaceDocument.content.members
      .filter(m => m.workspacePolicy === LevelPolicy.standard).length;

    if (workspaceDocument.content.workspaceStandardLimit !== -1
      && standardCount > workspaceDocument.content.workspaceStandardLimit!) {
      return 'The workspace has the maximum number of Standard users.';
    }

    return null; // Standard limit is valid
  }
}
