{
  "name": "platform-portal",
  "version": "2.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4201",
    "build": "ng build",
    "build-internal": "ng build --configuration internal",
    "build-gbl": "ng build --configuration global",
    "build-trn": "ng build --configuration trn",
    "build-development": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "stylelint": "npx stylelint \"**/*.scss\""
  },
  "private": true,
  "engines": {
    "npm": "10",
    "node": "20"
  },
  "dependencies": {
    "@angular/animations": "18.2.8",
    "@angular/cdk": "^18.2.5",
    "@angular/common": "18.2.8",
    "@angular/compiler": "18.2.8",
    "@angular/core": "18.2.8",
    "@angular/forms": "18.2.8",
    "@angular/material": "^18.2.6",
    "@angular/platform-browser": "18.2.8",
    "@angular/platform-browser-dynamic": "18.2.8",
    "@angular/router": "18.2.8",
    "@angular/service-worker": "18.2.8",
    "@azure/msal-angular": "^3.0.24",
    "@azure/msal-browser": "^3.24.0",
    "@faker-js/faker": "^9.0.3",
    "@types/json-patch": "^0.0.33",
    "ajv": "^8.17.1",
    "ajv-formats": "^3.0.1",
    "ejs": "^3.1.10",
    "fast-deep-equal": "^3.1.3",
    "i": "^0.3.7",
    "js-yaml": "^4.1.0",
    "luxon": "^3.5.0",
    "monaco-editor": "~0.50.0",
    "p-limit": "^6.1.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.7.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.7",
    "@angular/cli": "18.2.8",
    "@angular/compiler-cli": "18.2.8",
    "@types/jasmine": "^5.1.4",
    "@types/js-yaml": "^4.0.9",
    "@types/luxon": "^3.4.2",
    "@types/uuid": "^10.0.0",
    "angular-eslint": "18.3.1",
    "eslint": "^9.9.1",
    "jasmine-core": "^5.3.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "stylelint": "^16.9.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "typescript": "~5.5.4",
    "typescript-eslint": "8.2.0"
  }
}
