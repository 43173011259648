import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AccessTokenResponse, Claims, ServiceVersion } from '@core/models';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(private http: HttpClient) { }

  async getVersion(): Promise<ServiceVersion> {
    return await firstValueFrom(
      this.http.get<ServiceVersion>(environment.authApiBaseUrl + '/health/version'));
  }

  async me(): Promise<Claims> {
    return await firstValueFrom(
      this.http.get<Claims>(environment.authApiBaseUrl + '/auth/me'));
  }

  async getToken(grantType: string, refreshToken?: string): Promise<AccessTokenResponse> {
    const formData = new FormData();
    const headers = {};

    formData.append('grant_type', grantType);

    if (grantType === 'refresh_token' && refreshToken) {
      formData.append('refresh_token', refreshToken);
    }

    return firstValueFrom(
      this.http.post<AccessTokenResponse>(
        environment.authApiBaseUrl + '/auth/token',
        formData,
        { headers }));
  }

  async revoke(): Promise<boolean> {
    return await firstValueFrom(
      this.http.post<boolean>(environment.authApiBaseUrl + '/auth/revoke', ''));
  }
}
