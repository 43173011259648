import { Injectable } from '@angular/core';
import { Member, Document } from '@core/models';
import { DocumentTypes, RecordTypes } from '@shared/reference';
import { DocumentApiService } from './document-api.service';
import { MemberService } from './member.service';
import { v4 as uuidv4 } from 'uuid';
import pLimit from 'p-limit';
import { AdminApiService } from './admin-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserMemberService {

  constructor(
    private readonly adminApiService: AdminApiService,
    private readonly documentApiService: DocumentApiService,
    private readonly memberService: MemberService,
  ) {
  }

  async doesWorkspaceSupportPersons(workspaceId: string): Promise<boolean> {
    // TODO: Allow backend to specify if workspace supports persons
    const recordTypes = await this.documentApiService.getAllRecordTypes(workspaceId);
    return recordTypes.find(rt => rt.name === RecordTypes.person) !== undefined;
  }

  /**
   * Update the members and persons in a workspace. This method will create missing members
   * and persons, update existing persons and unlink persons that are not in the list.
   * @param workspaceId The workspace to update
   * @param members Information about the members
   * @returns The updated members
   */
  async updatePersonsInWorkspace(
    workspaceId: string,
    members: Member[]): Promise<Member[]> {

    const [workspace, personRecords] = await Promise.all([
      await this.adminApiService.getWorkspace(workspaceId),
      await this.documentApiService.getRecords(RecordTypes.person, workspaceId)
    ]);

    // Update existing persons
    const personOpLimit = pLimit(10);
    const personOperations: Promise<Document>[] = [];
    for (const memberAccess of workspace.content.members) {
      const member = members.find(m => m.id === memberAccess.id);
      if (!member) {
        continue;
      }
      const person = personRecords
        .find(p => p.id === member.id || p.content.fields.email === member.email);
      if (person) {
        // Update existing person
        personOperations.push(personOpLimit(() => this.documentApiService
          .patchRecord(
            workspaceId,
            person.id,
            RecordTypes.person,
            this.getActivePersonInstructions(member))
          .then(p => {
            if (p) {
              // Update the member with the reults from the backend
              member.name = p.content.fields.displayName;
              member.email = p.content.fields.email;
              member.workspacePolicy = p.content.fields.workspacePolicy;
            }
            return p;
          })));
      } else {
        // Create new person
        personOperations.push(personOpLimit(() =>
          this.documentApiService
            .createRecord(this.convertMemberToPersonRecord(member), workspaceId)
            .then(p => {
              if (p) {
                personRecords.push(p);
              }
              return p;
            })));
        }
      }
    await Promise.all(personOperations);

    // Unlink persons that are not in the list
    const unlinkOpLimit = pLimit(10);
    const unlinkOperations: Promise<Document>[] = [];
    for (const person of personRecords) {
      if (workspace.content.members.find(m => m.id === person.content.fields.memberId)) {
        continue;
      }
      unlinkOperations.push(unlinkOpLimit(() => this.documentApiService.patchRecord(
        workspaceId,
        person.id,
        RecordTypes.person,
        this.getDeletedPersonInstructions())));
    }
    await Promise.all(unlinkOperations);

    return members;
  }

  /**
   * Link a member to a person in a workspace, create the person if required
   * @param workspaceId The workspace where the person exists
   * @param member The member to link
   */
  async linkMemberToPerson(workspaceId: string,member: Member): Promise<void> {
    const person = await this.findPerson(workspaceId, member);

    if (person) {
      await this.documentApiService.patchRecord(
        workspaceId,
        person.id, RecordTypes.person, this.getActivePersonInstructions(member));
    } else {
      // Create and activate a new person
      await this.documentApiService.createRecord(
        this.convertMemberToPersonRecord(member),
        workspaceId);

      return;
    }
  }

  /**
   * Unlink a member from a person in a workspace and delete the person. If the person does
   * not exist, nothing happens.
   * @param workspaceId The workspace where the person exists
   * @param member The member to unlink
   */
  async unLinkMemberFromPerson(workspaceId: string, member: Member): Promise<void> {
    const person = await this.findPerson(workspaceId, member);

    if (person) {
      await this.documentApiService.patchRecord(
        workspaceId,
        person.id,
        RecordTypes.person,
        this.getDeletedPersonInstructions());
    }
  }

  private getActivePersonInstructions(member: Member): unknown[] {
    return [
      { op: 'replace', path: '/fields/state', value: 'active' },
      { op: 'replace', path: '/fields/memberId', value: member.id },
      { op: 'replace', path: '/fields/workspacePolicy', value: member.workspacePolicy },
      { op: 'replace', path: '/fields/displayName', value: member.name },
    ];
  }

  private getDeletedPersonInstructions(): unknown[] {
    return [
      { op: 'replace', path: '/fields/state', value: 'deleted' },
      { op: 'replace', path: '/fields/memberId', value: null },
      { op: 'replace', path: '/fields/workspacePolicy', value: '' },
    ];
  }

  private convertMemberToPersonRecord(member: Member): Document {

    const recordId = uuidv4();
    const newPerson: Document = {
      id: recordId,
      documentId: recordId,
      documentType: DocumentTypes.record,
      content: {
        recordType: RecordTypes.person,
        fields: {
          subType: RecordTypes.person,
          state: 'active',
          email: member.email,
          fullName: member.name,
          displayName: member.name,
          workspacePolicy: member.workspacePolicy,
          memberId: member.id
        },
        links: {}
      }
    }

    return newPerson;
  }

  private async findPerson(workspaceId: string, member: Member): Promise<Document | null> {
    // TODO: Improve query in the backend to allow OR queries
    let persons = await this.documentApiService.getRecords(
      RecordTypes.person,
      workspaceId,
      `limit=1&fields.memberId=${member.id}`);

    if (persons.length === 0) {
      // If we cannot find the person by member id, try to find it by email
      persons = await this.documentApiService.getRecords(
        RecordTypes.person,
        workspaceId,
        `limit=1&fields.email=${member.email}`);
    }
    return persons.length > 0 ? persons[0] : null;
  }
}
