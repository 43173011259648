import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'levelPolicy'
})
export class LevelPolicyPipe implements PipeTransform {
  private readonly _levelPolicyMap = {
    'superadmin': 'Super Admin',
    'admin': 'Admin',
    'standard': 'Standard',
    'readonly': 'Read Only',
  };


  transform(value: string, ..._args: unknown[]): string {
    return Object.keys(this._levelPolicyMap).includes(value)
      ? this._levelPolicyMap[value as keyof typeof LevelPolicyPipe.prototype._levelPolicyMap]
      : 'None';
  }
}
