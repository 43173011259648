import { Injectable } from '@angular/core';

import { Member, MemberContent, SecurityDocument } from '@core/models';
import { DocumentTypes } from '@shared/reference';
import { AdminApiService } from './admin-api.service';
import pLimit from 'p-limit';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private readonly adminApiService: AdminApiService,
  ) { }

  /**
   * Convert a member document to a member
   * @param member Member document to convert
   * @returns Member
   */
  convertMemberDocumentToMember(member: SecurityDocument<MemberContent>): Member {
    return {
      id: member.content.objectId,
      name: member.content.name,
      email: member.content.email,
      workspacePolicy: member.content.platformPolicy
    };
  }

  /**
   * Convert a member to a member document with the specified platform policy (optional)
   * @param member Member to convert
   * @param platformPolicy Platform policy to assign to the member, default is 'standard'
   * @returns Member document
   * @remarks
   * The member is not connected to a identity provider, not have a password,
   * be enabled, and is not service member.
   */
  converMemberToMemberDocument(
    member: Member,
    platformPolicy = 'standard',
  ): SecurityDocument<MemberContent> {

    return {
      id: member.id,
      documentType: DocumentTypes.member,
      content: {
        objectId: '',
        name: member.name,
        email: member.email,
        isEnabled: true,
        hasPassword: false,
        isServiceMember: false,
        platformPolicy: platformPolicy,
      },
    };
  }

  /**
   * Create missing members in the platform
   * @param members Members to create
   * @param platformPolicy Platform policy to assign to the members
   * @returns Created members
   * @throws Error if member creation fails
   * @remarks
   * This method will create members that do not exist in the platform. The member id will be
   * updated with the id of the created member.
   */
  async createMissingMembers(members: Member[], platformPolicy: string): Promise<Member[]> {
    const createdMembers: Member[] = [];
    const memberDocuments = await this.adminApiService.getMembers();
    const createOperations: Promise<SecurityDocument<MemberContent>>[] = [];
    const limit = pLimit(5);

    for (const member of members) {
      const memberDocument = memberDocuments.find(m => m.id === member.id);
      if (!memberDocument) {
        const memberDocument = this.converMemberToMemberDocument(member, platformPolicy);
        createOperations.push(limit(() =>
            this.adminApiService.createMember(memberDocument).then((m) => {
            member.id = m.id;
            return m;
          })));
        createdMembers.push(member);
      }
    }
    await Promise.all(createOperations);

    return createdMembers;
  }
}
