import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { AccessTokenResponse } from '@core/models';
import { AuthApiService, StorageKey, StorageService } from '@core/services';

import { Observable, catchError, filter, from, switchMap, take, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly isAuthenticated = signal(false);
  readonly isRefreshing = signal(false);

  constructor(
    private authApi: AuthApiService,
    private storageService: StorageService) {
    this.isAuthenticated.set(this.hasValidToken);
   }

  get hasValidToken(): boolean {
    return Date.now() < this.Expiration;
  }

  get hasRefreshToken(): boolean {
    return !!this.storageService.get(StorageKey.refreshToken);
  }

  async login(): Promise<void> {
    try {
      this.isAuthenticated.set(false);
      const response = await this.authApi.getToken('client_credentials');
      this.setSession(response);
      this.isAuthenticated.set(this.hasValidToken);
    } catch {
      this.logout();
      this.isAuthenticated.set(false);
    }
  }

  logout(): void {
    this.removeSession();
    this.isAuthenticated.set(false);
  }

  async forceRefreshOfToken(): Promise<void> {
    const refreshToken = this.storageService.get<string>(StorageKey.refreshToken)!;

    try {
      this.isAuthenticated.set(false);
      const response = await this.authApi.getToken('refresh_token', refreshToken);
      this.setSession(response);
      this.isAuthenticated.set(this.hasValidToken);
    } catch {
      this.logout();
      this.isAuthenticated.set(false);
    }
  }

  async forceRefreshOfTokenAsync(): Promise<void> {
    const refreshToken = this.storageService.get<string>(StorageKey.refreshToken)!;

    try {
        this.isAuthenticated.set(false);
        const response = await this.authApi.getToken('refresh_token', refreshToken);
        this.setSession(response);
        this.isAuthenticated.set(this.hasValidToken);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Retry with client_credentials if refresh token fails with 401
        try {
          this.isAuthenticated.set(false);
          const response = await this.authApi.getToken('client_credentials');
          this.setSession(response);
          this.isAuthenticated.set(this.hasValidToken);
        } catch {
          this.logout();
          this.isAuthenticated.set(false);
          throw error;
        }
      }
    }
  }

  refreshTokenAndAuthorize(
    request: HttpRequest<unknown>,
    next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!this.isRefreshing()) {
      this.isRefreshing.set(true);

      const refreshToken = this.storageService.get<string>(StorageKey.refreshToken)!;

      // First try with our refresh token
      return from(this.authApi.getToken('refresh_token', refreshToken)).pipe(
        switchMap((response: AccessTokenResponse) => {
          this.setSession(response);
          this.isRefreshing.set(false);
          return next.handle(this.authorize(request));
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // If the refresh token has expired, try with the client_credentials
            return from(this.authApi.getToken('client_credentials')).pipe(
              switchMap((response: AccessTokenResponse) => {
                this.setSession(response);
                this.isRefreshing.set(false);
                return next.handle(this.authorize(request));
              }));
          } else {
            // If we get an error other than 401, just pass it through
            this.isRefreshing.set(false);
            return throwError(() => error);
          }
        }),
      );
    } else {
      return toObservable(this.isRefreshing).pipe(
        filter(refreshing => !refreshing),
        take(1),
        switchMap(() => next.handle(this.authorize(request)))
      );
    }
  }

  authorize(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const accessToken: string = this.storageService.get(StorageKey.accessToken)!;
    const tokenType: string = this.storageService.get(StorageKey.tokenType)!;

    if (accessToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', tokenType + ' ' + accessToken)
      });
    }

    return request;
  }

  requireAuthentication(): void {
    if (!this.hasValidToken) {
      this.login();
    }
  }

  private setSession(response: AccessTokenResponse) {
    // Set expirection time and subtract one minute to give a buffer
    const expires_in = response.expires_in;
    this.storageService.set(StorageKey.expires, Date.now() + (expires_in - 60) * 1000);

    this.storageService.set(StorageKey.accessToken, response.access_token);
    this.storageService.set(StorageKey.tokenType, response.token_type);
    this.storageService.set(StorageKey.refreshToken, response.refresh_token);
  }

  private removeSession(): void {
    this.storageService.remove(StorageKey.expires);
    this.storageService.remove(StorageKey.accessToken);
    this.storageService.remove(StorageKey.tokenType);
    this.storageService.remove(StorageKey.refreshToken);
  }

  private get Expiration(): number {
    const expiration = this.storageService.get<string>(StorageKey.expires);
    // Use now as expiration if expiration is null
    return JSON.parse(expiration || Date.now().toString());
  }
}
