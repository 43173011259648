import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, from } from 'rxjs';

import { environment } from '@env';

import { MemberContent, SecurityDocument, ServiceVersion, WorkspaceContent } from '@core/models';
import jsonpatch from 'json-patch';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  private readonly postOptions = {
    observe: 'response' as const,
    responseType: 'json' as const,
  };

  constructor(private http: HttpClient) { }

  async getVersion(): Promise<ServiceVersion> {
    return await firstValueFrom(
      this.http.get<ServiceVersion>(environment.adminApiBaseUrl + '/health/version'));
  }

  //
  // Workspaces
  //

  createWorkspace$(content: SecurityDocument<WorkspaceContent>): Observable<SecurityDocument<WorkspaceContent>> {
    return from(this.createWorkspace(content));
  }

  async createWorkspace(content: SecurityDocument<WorkspaceContent>): Promise<SecurityDocument<WorkspaceContent>> {
    return await firstValueFrom(
      this.http.post<SecurityDocument<WorkspaceContent>>(
        environment.adminApiBaseUrl + '/workspaces',
        content));
  }

  async updateWorkspace(workspace: SecurityDocument<WorkspaceContent>): Promise<object> {
    return await firstValueFrom(
      this.http.put(environment.adminApiBaseUrl + `/workspaces/${workspace.id}`, workspace));
  }

  getWorkspaces$(): Observable<SecurityDocument<WorkspaceContent>[]> {
    return from(this.getWorkspaces());
  }

  async getWorkspaces(filter?: string): Promise<SecurityDocument<WorkspaceContent>[]> {
    return await firstValueFrom(
      this.http.get<SecurityDocument<WorkspaceContent>[]>(
        `${environment.adminApiBaseUrl}/workspaces${this.getQueryString(filter)}`));
  }

  getWorkspace$(id: string): Observable<SecurityDocument<WorkspaceContent>> {
    return from(this.getWorkspace(id));
  }

  async getWorkspace(id: string): Promise<SecurityDocument<WorkspaceContent>> {
    return await firstValueFrom(
      this.http.get<SecurityDocument<WorkspaceContent>>(environment.adminApiBaseUrl + `/workspaces/${id}`));
  }

  async patchWorkspace(patch: jsonpatch.OpPatch[], id: string): Promise<SecurityDocument<WorkspaceContent>> {
    return await firstValueFrom(
      this.http.patch<SecurityDocument<WorkspaceContent>>(
        environment.adminApiBaseUrl + `/workspaces/${id}`,
        patch));
  }

  //
  // Members
  //

  async createMember(content: SecurityDocument<MemberContent>): Promise<SecurityDocument<MemberContent>> {
    return await firstValueFrom(
      this.http.post<SecurityDocument<MemberContent>>(
        environment.adminApiBaseUrl + '/members',
        content));
  }

  async updateMember(content: SecurityDocument<MemberContent>, id: string): Promise<SecurityDocument<MemberContent>> {
    return await firstValueFrom(
      this.http.put<SecurityDocument<MemberContent>>(environment.adminApiBaseUrl + `/members/${id}`, content));
  }

  async getMembers(): Promise<SecurityDocument<MemberContent>[]> {
    return await firstValueFrom(
      this.http.get<SecurityDocument<MemberContent>[]>(environment.adminApiBaseUrl + '/members'));
  }

  async getMember(id: string): Promise<SecurityDocument<MemberContent>> {
    return await firstValueFrom(
      this.http.get<SecurityDocument<MemberContent>>(environment.adminApiBaseUrl + `/members/${id}`));
  }

  async patchMember(patch: jsonpatch.OpPatch[], id: string): Promise<SecurityDocument<MemberContent>> {
    return await firstValueFrom(
      this.http.patch<SecurityDocument<MemberContent>>(
        environment.adminApiBaseUrl + `/members/${id}`,
        patch));
  }

  private getQueryString(filter?: string): string {
    return filter ? '?' + filter : '';
  }

}
