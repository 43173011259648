import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Document } from '@core/models';
import { Observable, firstValueFrom, map } from 'rxjs';
import yaml from 'js-yaml';

export enum Library {
  DefaultEmailTemplate = 'common/default-email-template.yaml',
  DefaultRecordType = 'common/default-record-type.yaml',
  DefaultRecordTypeTranslation = 'common/default-record-type-en-gb-translation.yaml',
  DefaultDashboard = 'common/default-dashboard.yaml',
  DefaultDashboardTranslation = 'common/default-dashboard-en-gb-translation.yaml',
  DefaultReferenceData = 'common/default-reference-data.yaml',
  DefaultReferenceDataTranslationEnGB = 'common/default-reference-data-en-gb-translation.yaml',
  DefaultReferenceDataTranslationEsES = 'common/default-reference-data-es-es-translation.yaml',
  DefaultReferenceDataTranslationHiIN = 'common/default-reference-data-hi-in-translation.yaml',
  defaultTranslation = 'common/default-translation.yaml',
  DefaultTermsOfUse = 'common/default-terms-of-use.yaml',
  DefaultUserData = 'common/default-user-data.yaml',
  DefaultWorkspaceData = 'common/default-workspace-data.yaml',
  GlobalTranslation = 'common/global-en-gb-translation.yaml',
  LocationRecordType = 'common/location-type.yaml',
  LocationTranslation = 'common/location-en-gb-translation.yaml',
  PersonRecordType = 'common/person-type.yaml',
  PersonTranslation = 'common/person-en-gb-translation.yaml',
  RecordTypeTranslation = 'common/record-type-en-gb-translation.yaml',
  StarterRecordRecordType = 'starter/record-type.yaml',
  StarterRecordTranslation = 'starter/record-en-gb-translation.yaml',
  StarterReferenceData = 'starter/reference-data.yaml',
  StarterReferenceDataTranslation = 'starter/reference-en-gb-translation.yaml',
  StarterHomePageDocument = 'starter/home-page-document.yaml',
  StarterHomePageTranslation = 'starter/home-page-en-gb-translation.yaml',
  DashboardContentSchema = 'schemas/dashboard-content-schema.yaml',
  DocumentSchema = 'schemas/document-schema.yaml',
  RecordContentSchema = 'schemas/record-content-schema.yaml',
  RecordTypeContentSchema = 'schemas/record-type-content-schema.yaml',
  ReferenceDataContentSchema = 'schemas/reference-data-content-schema.yaml',
  TranslationContentSchema = 'schemas/translation-content-schema.yaml',
  StarterWorkspaceDataDocument = 'starter/workspace-data-document.yaml',
  UserDataDocument = 'common/user-data-document.yaml',
  TermsOfUseContentSchema = 'schemas/terms-of-use-content-schema.yaml',
  EmailTemplateContentSchema = 'schemas/email-template-content-schema.yaml',

  SmartAuditV2AuditRecordType = 'smart-audit-v2/audit-type.yaml',
  SmartAuditV2AuditTranslation = 'smart-audit-v2/audit-en-gb-translation.yaml',
  SmartAuditV2ChecklistRecordType = 'smart-audit-v2/checklist-type.yaml',
  SmartAuditV2ChecklistTranslation = 'smart-audit-v2/checklist-en-gb-translation.yaml',
  SmartAuditV2FindingRecordType = 'smart-audit-v2/finding-type.yaml',
  SmartAuditV2FindingTranslation = 'smart-audit-v2/finding-en-gb-translation.yaml',
  SmartAuditV2GlobalTranslation = 'smart-audit-v2/global-en-gb-translation.yaml',
  SmartAuditV2HomePageDocument = 'smart-audit-v2/home-page-document.yaml',
  SmartAuditV2HomePageTranslation = 'smart-audit-v2/home-page-en-gb-translation.yaml',
  SmartAuditV2LocationRecordType = 'smart-audit-v2/location-type.yaml',
  SmartAuditV2LocationTranslation = 'smart-audit-v2/location-en-gb-translation.yaml',
  SmartAuditV2PersonRecordType = 'smart-audit-v2/person-type.yaml',
  SmartAuditV2PersonTranslation = 'smart-audit-v2/person-en-gb-translation.yaml',
  SmartAuditV2RecordTypeTranslation = 'smart-audit-v2/record-type-en-gb-translation.yaml',
  SmartAuditV2ReferenceData = 'smart-audit-v2/reference-data-document.yaml',
  SmartAuditV2ReferenceDataTranslation = 'smart-audit-v2/reference-data-en-gb-translation.yaml',
  SmartAuditV2WorkspaceDataDocument = 'smart-audit-v2/workspace-data-document.yaml',

  AutomationActionRecordType = 'automation/action-type.yaml',
  AutomationActionTranslation = 'automation/action-en-gb-translation.yaml',
  AutomationAuditRecordType = 'automation/audit-type.yaml',
  AutomationAuditTranslation = 'automation/audit-en-gb-translation.yaml',
  AutomationChecklistRecordType = 'automation/checklist-type.yaml',
  AutomationChecklistTranslation = 'automation/checklist-en-gb-translation.yaml',
  AutomationFindingRecordType = 'automation/finding-type.yaml',
  AutomationFindingTranslation = 'automation/finding-en-gb-translation.yaml',
  AutomationGlobalTranslation = 'automation/global-en-gb-translation.yaml',
  AutomationHomePageDocument = 'automation/home-page-document.yaml',
  AutomationHomePageTranslation = 'automation/home-page-en-gb-translation.yaml',
  AutomationLocationRecordType = 'automation/location-type.yaml',
  AutomationLocationTranslation = 'automation/location-en-gb-translation.yaml',
  AutomationPersonRecordType = 'automation/person-type.yaml',
  AutomationPersonTranslation = 'automation/person-en-gb-translation.yaml',
  AutomationRecordTypeTranslation = 'automation/record-type-en-gb-translation.yaml',
  AutomationReferenceData = 'automation/reference-data-document.yaml',
  AutomationReferenceDataTranslation = 'automation/reference-data-en-gb-translation.yaml',
  AutomationWorkspaceDataDocument = 'automation/workspace-data-document.yaml',

  TakeawayAuditRecordType = 'takeaway/audit-type.yaml',
  TakeawayAuditTranslation = 'takeaway/audit-en-gb-translation.yaml',
  TakeawayChecklistRecordType = 'takeaway/checklist-type.yaml',
  TakeawayChecklistTranslation = 'takeaway/checklist-en-gb-translation.yaml',
  TakeawayFindingRecordType = 'takeaway/finding-type.yaml',
  TakeawayFindingTranslation = 'takeaway/finding-en-gb-translation.yaml',
  TakeawayGlobalTranslation = 'takeaway/global-en-gb-translation.yaml',
  TakeawayHomePageDocument = 'takeaway/home-page-document.yaml',
  TakeawayHomePageTranslation = 'takeaway/home-page-en-gb-translation.yaml',
  TakeawayLocationRecordType = 'takeaway/location-type.yaml',
  TakeawayLocationTranslation = 'takeaway/location-en-gb-translation.yaml',
  TakeawayPersonRecordType = 'takeaway/person-type.yaml',
  TakeawayPersonTranslation = 'takeaway/person-en-gb-translation.yaml',
  TakeawayRecordTypeTranslation = 'takeaway/record-type-en-gb-translation.yaml',
  TakeawayReferenceData = 'takeaway/reference-data-document.yaml',
  TakeawayReferenceDataTranslation = 'takeaway/reference-data-en-gb-translation.yaml',
  TakeawayWorkspaceDataDocument = 'takeaway/workspace-data-document.yaml',
  TakeawayTermsOfUseDocument = 'takeaway/terms-of-use-document.yaml',

  PlusPremiumActionRecordType = 'plus-premium/action-type.yaml',
  PlusPremiumActionTranslation = 'plus-premium/action-en-gb-translation.yaml',
  PlusPremiumAuditRecordType = 'plus-premium/audit-type.yaml',
  PlusPremiumAuditTranslation = 'plus-premium/audit-en-gb-translation.yaml',
  PlusPremiumChecklistRecordType = 'plus-premium/checklist-type.yaml',
  PlusPremiumChecklistTranslation = 'plus-premium/checklist-en-gb-translation.yaml',
  PlusPremiumFindingRecordType = 'plus-premium/finding-type.yaml',
  PlusPremiumFindingTranslation = 'plus-premium/finding-en-gb-translation.yaml',
  PlusPremiumGlobalTranslation = 'plus-premium/global-en-gb-translation.yaml',
  PlusPremiumHomePageDocument = 'plus-premium/home-page-document.yaml',
  PlusPremiumHomePageTranslation = 'plus-premium/home-page-en-gb-translation.yaml',
  PlusPremiumLocationRecordType = 'plus-premium/location-type.yaml',
  PlusPremiumLocationTranslation = 'plus-premium/location-en-gb-translation.yaml',
  PlusPremiumPersonRecordType = 'plus-premium/person-type.yaml',
  PlusPremiumPersonTranslation = 'plus-premium/person-en-gb-translation.yaml',
  PlusPremiumRecordTypeTranslation = 'plus-premium/record-type-en-gb-translation.yaml',
  PlusPremiumReferenceData = 'plus-premium/reference-data-document.yaml',
  PlusPremiumReferenceDataTranslation = 'plus-premium/reference-data-en-gb-translation.yaml',
  PlusPremiumWorkspaceDataDocument = 'plus-premium/workspace-data-document.yaml',
  PlusPremiumTermsOfUseDocument = 'plus-premium/terms-of-use-document.yaml',

}

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  private baseUri = '/assets/library/';
  private options = { responseType: 'text' as 'json' };

  constructor(private http: HttpClient) { }

  getItem$(item: Library): Observable<Document> {
    return this.http.get<string>(this.baseUri + item, this.options)
      .pipe(
        map((response) => (yaml.load(response) as Document)),
      );
  }

  async getItem(item: Library): Promise<Document> {
    return firstValueFrom(
      this.http.get<string>(this.baseUri + item, this.options)
        .pipe(
          map((response) => (yaml.load(response) as Document)),
        ));
  }
}
